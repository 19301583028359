
$(document).on("turbolinks:load", function(){
  // Pour directement se situer en bas du scroll des logs
  if ($('#reservation_logs').length){
    $('#reservation_logs').scrollTop($('#reservation_logs')[0].scrollHeight);
    $('#reservation_errors').scrollTop($('#reservation_errors')[0].scrollHeight);
  }

  $("#index-more").click(function(e) {
    // Prevent a page reload when a link is pressed
    e.preventDefault();
    // Call the scroll function
    $('html,body').animate({
      scrollTop: $("#more").offset().top
    }, 'slow');
  });


  if ($( "#index_time_slots" ).attr("data-calendar-need-activation") == 'true') {
    var alertify = initializeAlertify();
    alertify.alert(
      $('.inactive_calendar_popup_title').text(),
      $('.inactive_calendar_popup_message_html').html(),
      // function(){
      //   // confirmed(element);
      //   // alertify.success($('.inactive_calendar_popup_success').text());
      //   $('#calendar_deploy_without_confirm').click()
      // },
      // function(){
      //   alertify.warning($('.inactive_calendar_popup_cancelled').text());
      //   // alertify.error($('#alertify-defaults-glossary-cancelled').text())
      // },

    ).set(
      'label', $('.inactive_calendar_popup_label_ok').text()
    )//.set({onshow:null, onclose:function(){  alertify.confirm().destroy();  }});
  }

});
