// Pour activer les tooltips de bootstrap
window.tooltips = function(selector = '[data-toggle="tooltip"]', options = {}) {
  $(selector).tooltip(options);
  // Pour éviter que le tooltip reste affiché une fois qu'on click
  $(selector).click(function () {
    $(this).tooltip("hide");
  });
}

$(document).on("turbolinks:load", function(){
  // Pour activer les tooltips de bootstrap
  tooltips();
});
