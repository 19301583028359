require("core-js/stable")
require("regenerator-runtime/runtime")

//import 'jquery';

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('configurations/data_confirm.js')

// import Rails from '@rails/ujs';
// Rails.start();
require("@rails/ujs").start()
global.Rails = Rails;
// require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")
require("jquery-ui")

require('bootstrap')

// Les alerts JavaScript jolies
// import 'alertify/alertify';
// import 'alertify/confirm-modal';
// import 'alertify/confirm-ujs';


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('initializers/tooltips.js')
require('initializers/popovers.js')
require('initializers/ujs_pagination.js')
require('initializers/alertify.js')
require('initializers/date_time_picker.js')
require('initializers/cocoon.js')
require('initializers/time_slot_username.js')
// require('initializers/jstimezonedetect.js')


// require('initializers/toggle_checkbox.js')
// require('initializers/jquery_datetime_picker.js')
// require('initializers/tinymce.js')
// require('initializers/clipboard.js')
require('global.js')


// REACT Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);

// require('components/React.js')
