// Pour activer les tooltips de bootstrap
window.initializeTimeSlotUsername = function() {
  $('.btn-time_slot-reservation').click(function(e) {
    const time_slot_username = $('#time_slot_username').val();
    const time_slot_identifier = $('#time_slot_identifier').val();
    $(this).siblings("input[name='time_slot[username]']").val(time_slot_username);
    $(this).siblings("input[name='time_slot[identifier]']").val(time_slot_identifier);
    // alert(time_slot_username)
  });
}

$(document).on("turbolinks:load", function(){
  // Pour activer le fait d'aller chercher le username dans le module pro
  initializeTimeSlotUsername();
});
