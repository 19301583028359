// Initialiser datetime
window.initializeDateTimePicker = function() {
  require('pickadate/picker')
  require('pickadate/picker.date')
  require('pickadate/picker.time')
  require('pickadate/legacy')
  require('pickadate/translations/fr_FR')

  var min_time = $('.calendar-min-open-time').text();
  var max_time = $('.calendar-max-close-time').text();

  var time_picker_selecter = $('.time_picker_selecter').pickatime({
    interval: 60,
    min: min_time,
    max: max_time,
    hiddenSuffix: '',
  });

  var date_picker_selecter = $('.date_picker_selecter').pickadate({
    hiddenSuffix:'',
    formatSubmit: 'yyyy-mm-dd'
    // hiddenName: true
  });

  var time_slots_date_selecter = $('.time_slots_date_selecter').pickadate({
    hiddenSuffix:'',
    onSet: function(context) {
      const path = $(".time_slots_date_link_href").first().text()
      const date = new Date(context.select)

      if (date instanceof Date && !isNaN(date)) {
        // La date est valide
        const month = date.getMonth() + 1; //months from 1-12
        const day = date.getDate();
        const year = date.getFullYear();
        const parsed_date = year + "-" + month + "-" + day;

        const full_path = path + "?date=" + parsed_date
        $.ajax({
          url: full_path,  // 2020-04-13
          success: function(response) {
            window.history.pushState({"html":response.html,"pageTitle":response.pageTitle}, parsed_date, full_path);
          }
        })
        // window.location = "/calendars/1/time_slots.js?date=2020-04-13";
        // $('.time_slots_date_link').click();
      }
    }
  });

  return [date_picker_selecter, time_picker_selecter, time_slots_date_selecter]
}
// initializeDateTimePicker();


$(document).on("turbolinks:load", function(){
  var picker_selecters = initializeDateTimePicker();
});
