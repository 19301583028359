// Tuto ici : https://medium.com/store2be-tech/how-to-use-sweetalert2-for-your-rails-5-1-rails-ujs-confirms-without-jquery-8a5b516b2a1
// Deprecated : http://thelazylog.com/custom-dialog-for-data-confirm-in-rails/
require('initializers/alertify.js')

//Override the default confirm dialog by rails
const confirmed = (element) => {
  // User clicked confirm button
  element.removeAttribute('data-confirm')
  element.click()
}

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
  const message = element.getAttribute('data-confirm')
  const text = element.getAttribute('data-text')
  const label_ok = element.getAttribute('data-label-ok')
  const label_cancel = element.getAttribute('data-label-cancel')

  var alertify = initializeAlertify();
  // Pour remettre par default
  alertify.confirm().destroy();
  alertify.confirm(
    message || $('#alertify-defaults-glossary-confirm-title').text(),
    text || $('#alertify-defaults-glossary-confirm-message').text(),
    function(){
      confirmed(element);
    },
    function(){
      alertify.error($('#alertify-defaults-glossary-cancelled').text())
    },


  ).set(
    'labels', {
      ok: label_ok || $('#alertify-defaults-glossary-ok').text(),
      cancel: label_cancel || $('#alertify-defaults-glossary-cancel').text()
    }
  )
  // alertify({
  //   title: message || 'Are you sure?',
  //   text: text || '',
  //   type: 'warning',
  //   showCancelButton: true,
  //   confirmButtonText: 'Yes',
  //   cancelButtonText: 'Cancel',
  // }).then(result => confirmed(element, result))
}

const allowAction = (element) => {
  if (element.getAttribute('data-confirm') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

Rails.delegate(document, ['a[data-confirm]', 'input[data-confirm]', 'button[data-confirm]'], 'click', handleConfirm)
